<template>
  <div class="he-r ">
    <div class="dynamic-con">
      <h5 class="he-tit">我的资料</h5>
      <div class="my-info-box">
        <el-form ref="form" :model="form" label-width="100px">
          <!-- form-border -->
          <el-form-item class="" label="用户名称">
            <!-- <div class="form-view-box">
              <span class="data-view">朱世亮</span>
              <span class="set-view">您已完成传承人认证无法编辑</span>
            </div> -->

            <div class="form-view-box">
              <el-input placeholder="输入用户名称" v-model="form.nickName" :disabled="!editStatus.nickName">
                <span slot="suffix" @click="toggleEdit('nickName')" class="set-view">
                  <i class="editor-i"></i>{{ editStatus.nickName ? '保存' : '编辑' }}
                </span>
              </el-input>
            </div>
          </el-form-item>

          <el-form-item label="性别">
            <span slot="label">性<span style="margin-left: 35px">别</span></span>
            <div class="form-view-box">
              <el-select v-model="form.sex" placeholder="请选择" :disabled="!editStatus.sex">
                <el-option v-for="item in option1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span class="set-view" @click="toggleEdit('sex')">
                <i class="editor-i"></i>{{ editStatus.sex ? '保存' : '编辑' }}
              </span>
            </div>
          </el-form-item>

          <!-- <el-form-item label="所在地区">
            <div class="form-view-box">
              <el-cascader v-model="value" :options="options" separator=""></el-cascader>
            </div>
          </el-form-item> -->

          <el-form-item label="出生日期">
            <div class="form-view-box">
              <el-date-picker
                v-model="form.birthday"
                type="month"
                placeholder="选择日期"
                :disabled="!editStatus.birthday"
                value-format="yyyy-MM"
              >
              </el-date-picker>
              <span class="set-view" @click="toggleEdit('birthday')">
                <i class="editor-i"></i>{{ editStatus.birthday ? '保存' : '编辑' }}
              </span>
            </div>
          </el-form-item>

          <el-form-item label="手机号码">
            <div class="form-view-box">
              <span class="data-view">{{ form.phone }}</span>
              <span class="set-view" @click="toggleEdit('phone')">
                <i class="editor-i"></i>{{ editStatus.phone ? '保存' : '收起' }}
              </span>
            </div>
          </el-form-item>

          <div class="phone-info" v-show="editStatus.phone">
            <el-form ref="phoneForm" :model="phoneForm" label-width="120px" :rules="{}">
              <el-form-item label="旧手机号" prop="oldPhone">
                <el-input placeholder="请输入完整的旧手机号" v-model="phoneForm.oldPhone"></el-input>
              </el-form-item>
              <el-form-item label="新手机号" prop="newPhone">
                <el-input placeholder="请输入新手机号" v-model="phoneForm.newPhone"></el-input>
              </el-form-item>
              <el-form-item label="验证信息" prop="captchaCode">
                <el-input placeholder="请输入验证码" v-model="phoneForm.captchaCode">
                  <el-button slot="suffix" class="message-btn" @click="getCaptcha" :disabled="countdown !== 0">
                    <template v-if="countdown === 0">发送验证码</template>
                    <template v-else>{{ countdown }}s后重新发送</template></el-button
                  >
                </el-input>

                <el-button class="form-btn" @click="editPhone">确认修改</el-button>
              </el-form-item>
            </el-form>
          </div>

          <el-form-item label="个人邮箱">
            <div class="form-view-box">
              <el-input placeholder="输入邮箱" v-model="form.email" :disabled="!editStatus.email">
                <span slot="suffix" @click="toggleEdit('email')" class="set-view">
                  <i class="editor-i"></i>{{ editStatus.email ? '保存' : '编辑' }}
                </span>
              </el-input>
            </div>
          </el-form-item>

          <el-form-item label="个性签名">
            <div class="form-view-box">
              <el-input placeholder="输入签名" v-model="form.slogan" :disabled="!editStatus.slogan">
                <span slot="suffix" @click="toggleEdit('slogan')" class="set-view">
                  <i class="editor-i"></i>{{ editStatus.slogan ? '保存' : '编辑' }}
                </span>
              </el-input>
            </div>
          </el-form-item>

          <el-form-item class="form-border" label="注册时间">
            <div class="form-view-box">
              <span class="data-view">{{ form.createTime }}</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        nickName: '',
        sex: null,
        birthday: '',
        phone: '',
        email: '',
        slogan: '',
        createTime: ''
      },
      editStatus: {
        nickName: false,
        sex: false,
        birthday: false,
        phone: false,
        email: false,
        slogan: false,
        createTime: false
      },
      phoneForm: {
        oldPhone: '',
        newPhone: '',
        captchaKey: '',
        captchaCode: ''
      },
      countdown: 0,
      hide: false,
      option1: [
        {
          value: 1,
          label: '男'
        },
        {
          value: 2,
          label: '女'
        }
      ],
      values: '',
      options: [
        {
          value: 'zhinan',
          label: '福建省',
          children: [
            {
              value: 'shejiyuanze',
              label: '泉州市',
              children: [
                {
                  value: 'yizhi',
                  label: '惠安县'
                },
                {
                  value: 'fankui',
                  label: '泉港'
                },
                {
                  value: 'xiaolv',
                  label: '永春'
                },
                {
                  value: 'kekong',
                  label: '德化'
                }
              ]
            },
            {
              value: 'daohang',
              label: '南平',
              children: [
                {
                  value: 'cexiangdaohang',
                  label: '邵武'
                },
                {
                  value: 'dingbudaohang',
                  label: '顶部导航'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(userInfo) {
        this.form = userInfo
      }
    }
  },
  methods: {
    hides() {
      this.hide = !this.hide
    },
    toggleEdit(key) {
      if (this.editStatus[key] && key != 'phone') {
        this.editInfo(key)
      }
      this.editStatus[key] = !this.editStatus[key]
    },
    getCaptcha() {
      this.axios.get('/api/blade-auth/member/oauth/sms?mobile=' + this.phoneForm.oldPhone).then(res => {
        this.$message.success('发送成功')
        this.phoneForm.captchaKey = res.data.key
        // this.loginFormPhone.captchaCode = res.data.code
        this.countdown = 60
        let timer = setInterval(() => {
          this.countdown--
          if (this.countdown === 0) {
            clearInterval(timer)
          }
        }, 1000)
      })
    },
    editInfo(key) {
      let params = {}
      params[key] = this.form[key]
      this.axios.post('/api/portal/member/auth/current/submit', params).then(res => {
        this.$message.success('修改成功')
      })
    },
    editPhone() {
      this.$refs.phoneForm.validate(valid => {
        if (valid) {
          let form = { ...this.phoneForm }
          let headers = {
            'Captcha-Key': form.captchaKey,
            'Captcha-Code': form.captchaCode
          }
          delete form.captchaKey
          delete form.captchaCode
          this.axios
            .post('/api/portal/member/auth/current/submit', form, {
              headers
            })
            .then(res => {
              this.$message.success('修改成功')
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style scoped>
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #b93333;
  font-weight: 700;
}
</style>
